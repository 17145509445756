<template>
    <div class="custom-font" style="width: 100vw;height: 100vh;background-color: white;overflow-y: auto">
        <div class="accountform">
            <div
                style="width: 100%;padding: 0px 20px;box-sizing: border-box;margin-top: 0px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                <img src="../../assets/ipsyo_form_logo.png" style="width: 267px;height: auto;object-fit: cover;">
                <p
                    style=" color: #C04B64; font-size: 30px; font-weight: bold;text-align: center;margin-top: 20px;line-height: 40px;">
                    International Psychology Olympiad <br>Organization Registration</p>
            </div>
            <div style="color: #525252;font-size: 14px;line-height: 24px;margin-top: 20px;">
                <p> The Global Platform and Arena for Psychology Enthusiasts in High Schools</p>
                <p style="margin-top: 20px;">The International Psychology Olympiad (IPsyO) is an annual competition for
                    high school students. In
                    bringing together gifted students, the IPsyO competition challenges and stimulates these students to
                    expand their talents and potential to become the next generation of leaders in mind and behavior
                    science.</p>
                <p style="margin-top: 20px;">Web: www.ipsyo.org <br>Email: contact@ipsyo.org</p>
            </div>

            <el-form :model="form" :rules="formRules" ref="form"
                style="margin-top: 20px;width: 100%;padding: 0px 20px;box-sizing: border-box;" label-position="top">

                <div class="flex-row-center">
                    <el-form-item label="Country/Region" prop="account_country" class="flex-1">
                        <el-input v-model="form.account_country" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="State/Province" prop="account_province" class="flex-1">
                        <el-input v-model="form.account_province" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="Organization Name" prop="account_nameEn">
                    <el-input v-model="form.account_nameEn" placeholder="Please input"></el-input>
                </el-form-item>
                <el-form-item label="Contact Address" prop="contact_address">
                    <el-input v-model="form.contact_address" placeholder="Please input"></el-input>
                </el-form-item>
                <div class="flex-row-center">
                    <el-form-item label="Registrant Name" prop="contact_nameEN" class="flex-1">
                        <el-input v-model="form.contact_nameEN" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Position" prop="contact_title" class="flex-1">
                        <el-input v-model="form.contact_title" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <div class="flex-row-center">
                    <el-form-item label="Email Address" prop="contact_email" class="flex-1">
                        <el-input v-model="form.contact_email" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Phone Number" prop="contact_mobile" class="flex-1">
                        <el-input v-model="form.contact_mobile" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="How do you select and form your team?" prop="know_source">
                        <p style="color: #666;font-size: 14px;margin-top: -25px;margin-left: 10px;">Simply write how you
                            made choices. No
                            limit is set on the length.</p>
                        <el-input v-model="form.know_source" type="textarea" :rows="4"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="Curriculum type" prop="curriculum_types">
                    <el-checkbox-group v-model="form.curriculum_types"
                        style="width: 100%;display: flex;flex-direction: column;">
                        <div class="flex-row-center" style="flex: 1;">
                            <div class="flex-1">
                                <el-checkbox label="AP"></el-checkbox>
                            </div>
                            <div class="empty-divider"></div>
                            <div class="flex-1">
                                <el-checkbox label="BC/OSSD"></el-checkbox>
                            </div>
                        </div>

                        <div class="flex-row-center" style="flex: 1;">
                            <div class="flex-1">
                                <el-checkbox label="Blended Learning Center"></el-checkbox>
                            </div>
                            <div class="empty-divider"></div>
                            <div class="flex-1">
                                <el-checkbox label="General High School"></el-checkbox>
                            </div>
                        </div>

                        <div class="flex-row-center" style="flex: 1;">
                            <div class="flex-1">
                                <el-checkbox label="IB"></el-checkbox>
                            </div>
                            <div class="empty-divider"></div>
                            <div class="flex-1">
                                <el-checkbox label="IGCSE or A-level"></el-checkbox>
                            </div>
                        </div>
                        <div class="flex-row-center" style="flex: 1;">
                            <div class="flex-1">
                                <el-checkbox label="PGA"></el-checkbox>
                            </div>
                            <div class="empty-divider"></div>
                            <div class="flex-1" style="display: flex;">
                                <el-checkbox label="Others"></el-checkbox>
                                <el-input v-model="form.other_curriculum_type" placeholder="Please input"
                                    style="margin-left: 25px;"></el-input>
                            </div>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div class="row" style="margin-bottom: 50px">
                <span class="submit" @click="submit">Submit</span>
            </div>
        </div>
    </div>
</template>
<script>
import {
    saveAccountForm
} from '../../api/ipsye'
export default ({
    name: 'index',
    data() {
        return {
            form: {
                account_country: '',
                account_name: '',
                account_nameEn: '',
                account_province: '',

                contact_name: '',//wu
                contact_nameEN: '',
                contact_mobile: '',
                contact_address: '',
                contact_email: '',
                contact_title: '',

                curriculum_types: [],
                other_curriculum_type: '',

                know_source: '',

            },
            formRules: {
                account_province: [{ required: true, message: "Please input", trigger: "blur" }],
                curriculum_types: [
                    { required: true, message: "Please select", trigger: "blur" },
                ],
                account_country: [{ required: true, message: "Please input", trigger: "blur" }],
                account_nameEn: [{ required: true, message: "Please input", trigger: "blur" }],
                account_address: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_address: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_nameEN: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_title: [{ required: true, message: "Please input", trigger: "blur" }],
                contact_email: [{ required: true, message: "Please input", trigger: "blur" }],
                know_source: [{ required: true, message: "Please input", trigger: "blur" }],
            }
        }
    },
    mounted() {
    },
    methods: {
        submit() {
            this.$refs.form.validate((val) => {
                if (val) {
                    // console.log(JSON.stringify(this.form))
                    saveAccountForm(this.form).then((res) => {
                        if (res) {
                            this.$confirm("Registration received. We'll reach out to you within 3-5 business days.", 'Submit Successful', {
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                                confirmButtonClass: "user-confirm",
                                type: 'success'
                            })
                            // this.$message.success("Submit Successfully");
                        }
                    });
                }
            });
        }
    }
})
</script>
<style>
.el-message-box .el-message-box__btns .user-confirm {
    background-color: #C04B64;
    border-color: #C04B64;
}
</style>
<style scoped lang="scss">
.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;

    .flex-1 {
        flex: 1;
    }

    .empty-divider {
        width: 30px;

    }
}

.custom-font {
    font-family: 'Century Gothic';
}

.accountform {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: white;
    width: 820px;
    box-shadow: 0 2px 5px 1px rgba(124, 124, 124, 0.2);
    font-family: "DDINAlternateRegular";
    overflow: hidden;
    position: relative;

    .row {
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 20px;
        display: flex;
    }

    .el-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .el-radio-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .submit {
        padding: 10px 70px;
        background: #C04B64;
        color: #fff;
        cursor: pointer;
    }
}

/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-color: #C04B64;
}

/deep/ .el-input__inner:hover {
    border-color: #C04B64;
}

/deep/ .el-input__inner:focus {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner:hover {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner:focus {
    border-color: #C04B64;
}

/deep/ .el-form-item__label {
    font-size: 16px;
    color: #000000;
}

/deep/ .el-checkbox__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-radio__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #C04B64;
    border-color: #C04B64;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #C04B64;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background-color: #C04B64;
    border-color: #C04B64;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #C04B64;
}
</style>